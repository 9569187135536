var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('board-filter', {
    attrs: {
      "data-aos": "fade"
    },
    on: {
      "searchKey": function (searchKey) {
        _vm.filter.searchKey = searchKey;
      },
      "searchValue": function (searchValue) {
        _vm.filter.searchValue = searchValue;
      },
      "search": _vm.search
    }
  }), _c('table-secondary', {
    staticClass: "v-table--board v-table--mobile table-fixed",
    attrs: {
      "data-aos": "fade"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th-primary', {
          staticStyle: {
            "width": "8%"
          }
        }, [_vm._v("번호")]), _c('th-primary', {
          staticStyle: {
            "width": "78%"
          }
        }, [_vm._v("제목")]), _c('th-primary', {
          staticStyle: {
            "width": "14%"
          }
        }, [_vm._v("작성일")])], 1)])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function () {
        return [_c('tbody', [_vm._l(_vm.fixed, function (notification, index) {
          return _c('tr', {
            key: index,
            staticClass: "cursor-pointer",
            on: {
              "click": function ($event) {
                return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
              }
            }
          }, [_c('td', {
            staticClass: "text-center d-none d-md-table-cell"
          }, [_c('v-icon', [_vm._v("mdi-alert-circle-outline")])], 1), _c('td', {
            staticClass: "v-table--board__tit"
          }, [_c('div', {
            staticClass: "text-truncate"
          }, [_c('v-icon', {
            staticClass: "d-md-none mr-6px"
          }, [_vm._v("mdi-alert-circle-outline")]), _vm._v(_vm._s(notification.subject))], 1)]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(notification.createdAt.toDate()))])]);
        }), _vm._l(_vm.notifications, function (notification, index) {
          return _c('tr', {
            key: index,
            staticClass: "cursor-pointer",
            on: {
              "click": function ($event) {
                return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
              }
            }
          }, [_c('td', {
            staticClass: "text-center d-none d-md-table-cell"
          }, [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + index + 1))]), _c('td', {
            staticClass: "v-table--board__tit"
          }, [_c('div', {
            staticClass: "text-truncate"
          }, [_c('b', {
            staticClass: "d-md-none mr-6px"
          }, [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + index + 1) + ".")]), _vm._v(_vm._s(notification.subject) + " ")])]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(notification.createdAt.toDate()))])]);
        })], 2)];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('pagination-primary', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "5"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }