<template>
    <div v-if="notification">
        <v-divider class="grey darken4" style="border-width: 2px 0 0" />
        <div class="pa-12px px-md-20px py-md-16px">
            <tit-primary>{{ notification.subject }}</tit-primary>
        </div>
        <v-divider class="border-light" />

        <div class="pa-12px px-md-20px py-md-24px">
            <v-row>
                <v-col cols="auto">
                    <v-row class="row--xxs">
                        <v-col cols="auto"> 작성일 </v-col>
                        <v-col cols="auto">
                            {{ notification.createdAt.toDate() }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto">
                    <v-row class="row--xxs">
                        <v-col cols="auto"> 조회수 </v-col>
                        <v-col cols="auto">
                            {{ notification.viewCount }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="notification.upload" cols="auto">
                    <v-row v-if="item.attachment" class="row--xxs">
                        <v-col cols="12">
                            <a class="d-inline-flex align-center" @click="download(notification.upload, notification.upload.name.split('/').pop())">
                                <txt-primary>{{ notification.upload.name.split("/").pop() }}</txt-primary>
                                <i class="icon icon-download ml-8px" />
                            </a>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-divider class="border-light" />

        <div class="pa-12px px-md-20px py-md-24px min-h-200px">
            <div class="overflow-x-auto">
                <div class="grey--text font-size-16">
                    <div v-html="notification.content.replace(/\n/g, '<br>')" />
                </div>
            </div>
        </div>
        <v-divider class="border-light" />

        <div class="btn-wrap btn-wrap--sm">
            <v-row align="center" justify="end">
                <v-col cols="auto">
                    <btn-secondary2 @click="$router.go(-1)">리스트</btn-secondary2>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import download from "downloadjs";

import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";

export default {
    components: {
        TitPrimary,
        TxtPrimary,
        BtnSecondary2,
    },
    data() {
        return {
            notification: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        download,
        async init() {
            try {
                var { notification } = await api.v1.center.notifications.get({ _id: this.$route.params._notification });
                if (notification.upload) notification.upload = await api.getResource(notification.upload);
                this.notification = notification;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
