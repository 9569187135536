<template>
    <div>
        <board-filter data-aos="fade"
        @searchKey="(searchKey) => { filter.searchKey = searchKey }"
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="search"
        />

        <table-secondary data-aos="fade" class="v-table--board v-table--mobile table-fixed">
            <template v-slot:header>
                <thead>
                    <tr>
                        <th-primary style="width: 8%">번호</th-primary>
                        <th-primary style="width: 78%">제목</th-primary>
                        <th-primary style="width: 14%">작성일</th-primary>
                    </tr>
                </thead>
            </template>
            <template v-slot:body>
                <tbody>
                    <tr v-for="(notification, index) in fixed" :key="index" class="cursor-pointer" @click="$router.push(`${$route.path}/${notification._id}`)">
                        <td class="text-center d-none d-md-table-cell"><v-icon>mdi-alert-circle-outline</v-icon></td>
                        <td class="v-table--board__tit">
                            <div class="text-truncate"><v-icon class="d-md-none mr-6px">mdi-alert-circle-outline</v-icon>{{ notification.subject }}</div>
                        </td>
                        <td class="text-center">{{ notification.createdAt.toDate() }}</td>
                    </tr>
                    <tr v-for="(notification, index) in notifications" :key="index" class="cursor-pointer" @click="$router.push(`${$route.path}/${notification._id}`)">
                        <td class="text-center d-none d-md-table-cell">{{ (page - 1) * limit + index + 1 }}</td>
                        <td class="v-table--board__tit">
                            <div class="text-truncate">
                                <b class="d-md-none mr-6px">{{ (page - 1) * limit + index + 1 }}.</b>{{ notification.subject }}
                            </div>
                        </td>
                        <td class="text-center">{{ notification.createdAt.toDate() }}</td>
                    </tr>
                </tbody>
            </template>
        </table-secondary>

        <div class="pagination-wrap"><pagination-primary v-model="page" :length="pageCount" total-visible="5" @input="search(true)" /></div>
    </div>
</template>

<script>
import api from "@/api";
import { mdiAlertCircleOutline } from "@mdi/js";

import TabsPrimary from "@/components/publish/parents/tabs/tabs-primary.vue";
import TabPrimary from "@/components/publish/parents/tabs/tab-primary.vue";
import TablePrimary from "@/components/publish/parents/tables/table-primary.vue";
import TableSecondary from "@/components/publish/parents/tables/table-secondary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";
import PaginationPrimary from "@/components/publish/parents/paginations/pagination-primary.vue";

import BoardFilter from "@/components/client/board/board-filter.vue";

export default {
    components: {
        TabsPrimary,
        TabPrimary,
        TablePrimary,
        TableSecondary,
        ThPrimary,
        TdPrimary,
        PaginationPrimary,

        BoardFilter,
    },
    props: {
        value: { type: String, default: null },
    },
    data() {
        return {
            mdiAlertCircleOutline,

            filter: { isNotice: false },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 20,

            fixed: [],
            notifications: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            this.$router.push({
                query: Object.assign({}, this.query, { page: this.page }),
            });

            var { notifications: fixed } = await api.v1.center.notifications.gets({
                headers: {
                    limit: 0,
                },
                params: { isNotice: true },
            });

            this.fixed = fixed;

            var { summary, notifications } = await api.v1.center.notifications.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.notifications = notifications;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    },
};
</script>
