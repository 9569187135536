<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="게시판" tabActive="기본게시판" bg="/images/test.jpg">
                <template #tit>게시판</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <router-view name="board" code="board-default" skin="board-default" hide-write-button v-bind="{ category }" />
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
    },
    computed: {
        category() {
            return this.$route.query.category || undefined;
        },
    },
};
</script>
